import React, { useContext, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceCenter,
  forceCollide,
  SimulationNodeDatum,
  Simulation,
} from 'd3-force';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  OnEdgesDelete,
  Controls,
  type Node,
  Edge,
  Connection,
} from '@xyflow/react';
import { UUID } from 'core/types';
import { FlowCard } from './_components/FlowCard';

import '@xyflow/react/dist/style.css';

interface Link {
  from: string;
  to: string;
}

type SimNode = SimulationNodeDatum & { id: UUID };

const nodeTypes = {
  card: FlowCard,
};

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center columns horizontally */
  height: 100vh; /* Full viewport height */
  background-size: 10px 10px;
  background-position: 0 0;
  overflow: auto;
`;

const FlowD3ForceSurface: React.FC = () => {
  const { shownIds, surfaceNoteMap } = useContext(ShownIdsContext);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { linkNotes, unlinkNotes } = useBrainContext();

  const onConnect = useCallback(
    (params: Connection) => {
      const { source: fromId, target: toId } = params;

      linkNotes(surfaceNoteMap[fromId], surfaceNoteMap[toId]);
      // setEdges((eds) => addEdge(params, eds)), [setEdges];
    },
    [linkNotes, surfaceNoteMap],
  );

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (edgesToDelete) => {
      edgesToDelete.forEach((edge) => {
        const fromNote = surfaceNoteMap[edge.source];
        const toNote = surfaceNoteMap[edge.target];
        if (fromNote && toNote) {
          unlinkNotes(fromNote, toNote);
        }
      });
    },
    [unlinkNotes, surfaceNoteMap],
  );

  const simulationRef = useRef<Simulation<SimulationNodeDatum, undefined> | null>(null);

  // maintain the nodes and edges from the shownIds and surfaceNoteMap
  useEffect(() => {
    const shownNotes = shownIds.map((id) => surfaceNoteMap[id]).filter((note) => note && note.type !== 'annote');

    const nodeMap = nodes.reduce((acc, n) => {
      acc[n.id] = n;
      return acc;
    }, {} as Record<UUID, Node>);

    const newNodes: Node[] = shownNotes.map((note) => ({
      id: note.id,
      type: 'card',
      position: nodeMap[note.id]?.position || { x: 0, y: 0 },
      data: note,
    }));
    setNodes(newNodes);
    console.log('outside - new nodes are', newNodes, 'from shownNotes', shownNotes);

    // Handle populating edges
    const shownLinks = shownNotes.reduce<Link[]>((acc, n) => {
      const linksInShownIds = n.links.filter((id) => shownIds.includes(id));
      linksInShownIds.forEach((linkId) => {
        acc.push({ from: n.id, to: linkId });
      });
      return acc;
    }, []);
    const newEdges: Edge[] = shownLinks.map((link) => ({
      id: `link-${link.from}-${link.to}`,
      source: link.from,
      target: link.to,
    }));
    setEdges(newEdges);

    if (newNodes.length === 0) return () => {};

    // Run simulation to position eveyrthing
    const simulationNodes = newNodes.map(
      (n, i) =>
        ({
          x: n.position.x,
          y: n.position.y,
          id: n.id,
          index: i, // shownIds.indexOf(n.id),
        } as SimNode),
    );

    console.log('simulation starting', simulationNodes, newNodes, nodes);
    // Initialize D3-force simulation
    const simulation = forceSimulation(simulationNodes)
      .force(
        'link',
        forceLink<SimNode, Edge>(edges)
          .id((d) => d.id)
          .distance(50),
      )
      .force('charge', forceManyBody().strength(-100))
      .force('center', forceCenter(window.innerWidth / 2, window.innerHeight / 2))
      .force('collision', forceCollide().radius(30))
      .on('tick', () => {
        console.log(
          'tick - repositioning nodes',
          simulationNodes.map((n) => ({ x: n.x, y: n.y, id: n.id })),
        );
        setNodes((prevNodes) => {
          const simNodeMap = simulationNodes.reduce((acc, n) => {
            acc[n.id] = n;
            return acc;
          }, {} as Record<UUID, SimNode>);
          return prevNodes.map((prevNode) => ({
            ...prevNode,
            position: { x: simNodeMap[prevNode.id]?.x ?? 0, y: simNodeMap[prevNode.id]?.y ?? 0 },
          }));
        });
      });

    // @ts-expect-error - fucking stupid shit
    simulationRef.current = simulation as Simulation<SimNode, undefined>;
    return () => {
      if (simulationRef.current) {
        simulationRef.current.stop();
      }
    };
  }, [shownIds, surfaceNoteMap, setNodes, setEdges]);

  useEffect(() => {}, []);

  return (
    <Surface id="surface">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onConnect={onConnect}
      >
        <Controls position="bottom-right" />
      </ReactFlow>
    </Surface>
  );
};

export default FlowD3ForceSurface;
