import React, { useCallback, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import dagre from 'dagre';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  type Node,
  type OnConnectStartParams,
  Edge,
  Connection,
  OnEdgesDelete,
  useReactFlow,
  OnConnectEnd,
  NodeOrigin,
  SelectionMode,
  ReactFlowProvider,
  CoordinateExtent,
  useViewport,
} from '@xyflow/react';
import { colors } from 'core/styles';
import { createNote } from 'core/utils/notes';
import { useDragging } from 'src/context/DraggingContext';
import { FlowCard } from './_components/FlowCard';
import '@xyflow/react/dist/style.css';
import { AutoArrangeButton } from './_components/ArrangeControl';

const nodeOrigin: NodeOrigin = [0.5, 0];

interface Link {
  from: string;
  to: string;
}

const nodeTypes = {
  card: FlowCard,
};

// 1 - left mouse button
// 2 - right mouse button
const panOnDrag = [1, 2];

const SurfaceWrapper = styled.div``;

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: 10px 10px;
  background-position: 0 0;
  overflow: auto;

  & .react-flow__edge-path {
    stroke: ${colors.lines.flowLink} !important;
    stroke-width: 1.5;
  }

  & .selected .react-flow__edge-path {
    stroke: ${colors.lines.selected} !important;
  }
`;

// TODO - set this programatically based on the actual node
const NODE_WIDTH = 35 * 8;
const NODE_HEIGHT = 168;

// Create a new context

const FlowDagreSurfaceContent: React.FC = () => {
  const { shownIds, surfaceNoteMap } = useContext(ShownIdsContext);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { linkNotes, unlinkNotes, updateNotes } = useBrainContext();
  const { showAafterB, showAbeforeB, hide } = useContext(ShownIdsContext);
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const { setDraggingConnectionId, setDraggingConnectionType, setIsOverLibrary } = useDragging();
  const reactFlowInstance = useReactFlow();
  const { x: viewportX, zoom } = useViewport();
  const draggedNodeRef = useRef<Node | null>(null);
  const { setDraggingNoteId } = useDragging();

  const onPaneDoubleClick = useCallback(
    (event: React.MouseEvent) => {
      // Prevent the default behavior
      event.preventDefault();

      // Get the position of the double click in flow coordinates
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });

      // Create a new note
      const newNote = createNote('', 'takeaway', '');

      // Create a new node
      const newNode: Node = {
        id: newNote.id,
        type: 'card',
        position,
        data: newNote,
      };

      // Add the new node to the existing nodes
      setNodes((nds) => nds.concat(newNode));
      updateNotes([newNote]);
    },
    [screenToFlowPosition, setNodes],
  );

  // Connecting two notes
  const onConnect = useCallback(
    (params: Connection) => {
      const { source: fromId, target: toId } = params;
      if (!fromId || !toId) return;
      linkNotes(surfaceNoteMap[fromId], surfaceNoteMap[toId]);
    },
    [linkNotes, surfaceNoteMap],
  );

  // Create a new note and link it if an edge is dropped on an empty space
  const onConnectEnd: OnConnectEnd = useCallback(
    async (event: MouseEvent | TouchEvent, connectionState) => {
      setDraggingConnectionId(null);
      setDraggingConnectionType(null);
      // when a connection is dropped on the pane it's not valid
      if (!connectionState.isValid) {
        // we need to remove the wrapper bounds, in order to get the correct position

        const newNote = createNote('', 'takeaway', '', connectionState?.fromNode?.id);
        const fromId = connectionState?.fromNode?.id;
        console.log(
          'Creating a new note from drag drop',
          fromId,
          'to',
          connectionState.toNode,
          'from handle type',
          connectionState.fromHandle?.type,
        );
        if (!fromId) return;
        const { clientX, clientY } = 'changedTouches' in event ? event.changedTouches[0] : event;

        const newNode = {
          id: newNote.id,
          position: screenToFlowPosition({
            x: clientX,
            y: clientY,
          }),
          data: newNote,
          origin: [0.5, 0.0],
        } as Node;
        setNodes((nds) => nds.concat(newNode));
        if (connectionState.fromHandle?.type === 'source') {
          await linkNotes(surfaceNoteMap[fromId], newNote);
          await showAafterB(newNote.id, fromId);
        } else {
          await linkNotes(newNote, surfaceNoteMap[fromId]);
          await showAbeforeB(newNote.id, fromId);
        }
      }
    },
    [screenToFlowPosition, linkNotes, surfaceNoteMap],
  );

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (edgesToDelete) => {
      edgesToDelete.forEach((edge) => {
        const fromNote = surfaceNoteMap[edge.source];
        const toNote = surfaceNoteMap[edge.target];
        if (fromNote && toNote) {
          unlinkNotes(fromNote, toNote);
        }
      });
    },
    [unlinkNotes, surfaceNoteMap],
  );

  // initial layout calculations using dagre
  const handleAutoArrange = useCallback(() => {
    const shownNotes = shownIds.map((id) => surfaceNoteMap[id]).filter((note) => note && note.type !== 'annote');

    const newNodes: Node[] = shownNotes.map((note) => ({
      id: note.id,
      type: 'card',
      position: { x: 0, y: 0 }, // Initial position, will be updated later
      data: note,
    }));

    const shownLinks = shownNotes.reduce<Link[]>((acc, n) => {
      const linksInShownIds = n.links.filter((id) => shownIds.includes(id));
      linksInShownIds.forEach((linkId) => {
        acc.push({ from: n.id, to: linkId });
      });
      return acc;
    }, []);

    const newEdges: Edge[] = shownLinks.map((link) => ({
      id: `link-${link.from}-${link.to}`,
      source: link.from,
      target: link.to,
      type: 'smoothstep', // 'simplebezier',
      curvature: -0.5, // Negative value for upward arc
    }));

    // Create a new Dagre graph
    const g = new dagre.graphlib.Graph();
    g.setDefaultEdgeLabel(() => ({}));

    // Set graph options
    g.setGraph({ rankdir: 'RL', align: 'UL', nodesep: 100, ranksep: 150 });

    // Add nodes to the graph
    newNodes.forEach((node) => {
      // TODO - get this based on the actual node dimensions
      g.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    });

    // Add edges to the graph
    newEdges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    // Run the Dagre layout
    dagre.layout(g);

    // Find the leftmost node
    let minX = Infinity;
    newNodes.forEach((node) => {
      const nodeWithPosition = g.node(node.id);
      minX = Math.min(minX, nodeWithPosition.x);
    });

    const xOffset = Math.max(300, minX);
    const yOffset = 0;

    // Update node positions based on Dagre layout
    const layoutedNodes = newNodes.map((node) => {
      const nodeWithPosition = g.node(node.id);

      return {
        ...node,
        position: {
          x: nodeWithPosition.x - minX + xOffset,
          y: nodeWithPosition.y - NODE_HEIGHT / 2 + yOffset,
        },
      };
    });

    setNodes(layoutedNodes);
    setEdges(newEdges);
  }, [shownIds, surfaceNoteMap, setNodes, setEdges]);

  const translateExtent: CoordinateExtent = [
    [0, -Infinity], // [left, top]
    [Infinity, Infinity], // [right, bottom]
  ];

  const onNodeDragStart = useCallback((event: React.MouseEvent, node: Node) => {
    setDraggingNoteId(node.id);
    draggedNodeRef.current = node;
  }, []);

  const onNodeDrag = useCallback((event: React.MouseEvent, node: Node) => {
    // Update the reference to the most recent node position
    draggedNodeRef.current = node;

    // if the node is dragged out of the viewport, set isOverLibrary to true
    const draggingNode = draggedNodeRef.current;
    const flowBounds = reactFlowInstance.getViewport();
    const nodeLeft = draggingNode.position.x * zoom + viewportX;

    const isOutsideLeft = nodeLeft < flowBounds.x;
    setIsOverLibrary(isOutsideLeft);
    if (isOutsideLeft) {
      console.log(`Node ${draggingNode.id} is outside the left half of the viewport`);
    }
  }, []);

  const onNodeDragStop = useCallback(() => {
    setDraggingNoteId(null);
    setIsOverLibrary(false);
    if (!draggedNodeRef.current) return;

    const node = draggedNodeRef.current;
    const flowBounds = reactFlowInstance.getViewport();
    const nodeLeft = node.position.x * zoom + viewportX;

    if (nodeLeft < flowBounds.x) {
      console.log(`Node ${node.id} is outside the left half of the viewport`);
      // Here you can add logic to hide the node
      hide(node.id);
    }

    draggedNodeRef.current = null;
  }, [zoom, viewportX, reactFlowInstance, hide]);

  const refitView = useCallback(() => {
    setTimeout(() => {
      reactFlowInstance.fitView({ padding: 0.2, duration: 200 });
    }, 100);
  }, [reactFlowInstance]);

  useEffect(() => {
    console.log('Auto arranging nodes');
    handleAutoArrange();
    refitView();
  }, [handleAutoArrange, refitView]);

  return (
    <SurfaceWrapper>
      <Surface id="surface" ref={reactFlowWrapper}>
        <ReactFlow
          // defaultViewport={{ x: 100, y: 0, zoom: 0.5 }} // Adjust the zoom value as needed
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={(event: MouseEvent | TouchEvent, params: OnConnectStartParams) => {
            setDraggingConnectionId(params.nodeId);
            setDraggingConnectionType(params.handleType);
          }}
          onEdgesDelete={onEdgesDelete}
          fitView
          onConnectEnd={onConnectEnd}
          nodeOrigin={nodeOrigin}
          translateExtent={translateExtent}
          // panning options
          panOnScroll={true}
          selectionOnDrag={true}
          panOnDrag={panOnDrag}
          selectionMode={SelectionMode.Partial}
          onPaneClick={(event) => {
            if (event.detail === 2) {
              onPaneDoubleClick(event);
            }
          }}
          proOptions={{ hideAttribution: true }}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          noDragClassName="ProseMirror-focused"
          selectNodesOnDrag={false}
        >
          <Controls position="bottom-right">
            <AutoArrangeButton onArrange={handleAutoArrange} />
          </Controls>
        </ReactFlow>
      </Surface>
    </SurfaceWrapper>
  );
};

const FlowDagreSurface: React.FC = () => {
  return (
    <ReactFlowProvider>
      <FlowDagreSurfaceContent />
    </ReactFlowProvider>
  );
};

export default FlowDagreSurface;
