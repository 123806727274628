/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Note } from 'core/types';
import { Card } from 'src/components/Cards/Card';
import { ShownTree } from 'src/types';
import { LinkPort, BacklinkPort } from 'src/components/Cards/Port';
import { ShownIdsContext } from 'src/context/ShownContext';
import HiddenCard, { HiddenWrapperTop, HiddenWrapperBottom } from 'src/components/Cards/HiddenCard';
import { CardDropzone } from 'src/components/Cards/CardDropzone';
import { PortRefMap } from 'src/helpers/ports';
import { CardSpacer } from 'src/components/Cards/_components/CardSpacer';

export interface CardTreeProps {
  note: Note;
  shownTree: ShownTree;
  setPortRefMap: React.Dispatch<React.SetStateAction<PortRefMap>>;
  parent?: Note;
  parentPortRef?: React.RefObject<HTMLDivElement>;
}

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: 0;
  padding-left: 0.35rem;
  margin-top: -4px;
`;

const CardTreeWrapper = styled.div`
  position: relative;
  display: flex;
  padding-left: 3.5rem;
  pointer-events: auto;
  flex: 1;
  width: fit-content;
`;

const TreePadding = styled.div`
  padding-left: 3.5rem;
  position: relative;
`;

const Branch = styled.div`
  position: relative;
`;

// const NonHoverableArea = styled.div<{ offset: number; isLeft: boolean }>`
//   width: ${(props) => props.offset * 1}rem;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: ${(props) => (props.isLeft ? 'unset' : `${-1 * props.offset}rem`)};
//   right: ${(props) => (props.isLeft ? `${-1 * props.offset}rem` : 'unset')};
//   z-index: 11;
//   pointer-events: auto;
// `;

const CardWrapper = styled.div`
  position: relative;
  height: fit-content;

  &:hover {
    z-index: 1001;
  }
`;

export const CardTree: React.FC<CardTreeProps> = ({ note, shownTree, setPortRefMap, parent, parentPortRef }) => {
  const { showAafterB, hide, showAbeforeB } = useContext(ShownIdsContext);

  const backlinkPortRef = React.useRef<HTMLDivElement>(null);
  const linkPortRef = React.useRef<HTMLDivElement>(null);

  const { id } = note;
  const shownBranches = shownTree.shownBranches[id] || [];

  useEffect(() => {
    // Update portRefMap using the setPortRefMap function
    setPortRefMap((prevMap) => ({
      ...prevMap,
      [`backlink-${note.id}`]: backlinkPortRef,
      [`link-${note.id}`]: linkPortRef,
    }));

    return () => {
      // Remove the references from portRefMap when the component unmounts
      setPortRefMap((prevMap) => {
        const newMap = { ...prevMap };
        delete newMap[`backlink-${note.id}`];
        delete newMap[`link-${note.id}`];
        return newMap;
      });
    };
  }, [note]);

  const toggleLinks: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const hiddenIds = shownTree.hiddenLinkNotes[id]?.map((hiddenNote) => hiddenNote.id) || [];
      if (hiddenIds.length) {
        // If there are hidden links, show them all
        showAbeforeB(hiddenIds, id);
        return;
      }
      // get which links are actually shown from the shownLinksInfo
      // can't just use note.links because it doesn't account for annote rollups
      const shownLinkIds = shownTree.shownLinksInfo
        .filter((link) => link.fromNote.id === id)
        .map((link) => link.toNote.id);
      hide(shownLinkIds);
    },
    [shownTree, id, showAafterB],
  );

  const toggleBacklinks: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const hiddenIds = shownTree.hiddenBacklinkNotes[id]?.map((hiddenNote) => hiddenNote.id).filter((n) => n) || [];
      if (hiddenIds.length) {
        // If there are hidden links, show them all
        showAafterB(hiddenIds, id);
        return;
      }
      // none are hidden so hide any shown
      hide(shownBranches.map((n: Note) => n.id) || []);
    },
    [shownTree, id, showAafterB],
  );

  const hiddenBackLinks = shownTree.hiddenBacklinkNotes[id].filter(
    (hiddenBacklink) => hiddenBacklink?.type && hiddenBacklink.type !== 'annote',
  );
  const hiddenLinks = shownTree.hiddenLinkNotes[id].filter(
    (hiddenNote) => hiddenNote?.type && hiddenNote.type !== 'annote',
  );

  const showLinkPort = useMemo(
    () =>
      // show the Port if there are shown links drawn, or hidden notes
      shownTree.hiddenLinkNotes[id].length > 0 || !!shownTree.shownLinksInfo.find((link) => link.fromNote.id === id),
    [shownTree.shownLinksInfo, id, shownTree.hiddenLinkNotes],
  );

  const showBacklinkPort = useMemo(
    () =>
      // show the Port if there are shown links drawn, or hidden notes
      shownTree.hiddenBacklinkNotes[id].length > 0 || !!shownTree.shownLinksInfo.find((link) => link.toNote.id === id),
    [shownTree.shownLinksInfo, id, shownTree.hiddenBacklinkNotes],
  );

  // const showBacklinksConnector = shownBranches.length > 0 || hiddenBackLinks.length > 0;

  return (
    <CardTreeWrapper>
      <div>
        <CardWrapper>
          <HiddenWrapperTop numOfBacklinks={hiddenBackLinks.length}>
            {hiddenBackLinks.map((hiddenNote, i) => (
              <HiddenCard
                key={`hl-${hiddenNote.id}`}
                note={hiddenNote}
                onClick={(e) => {
                  e.stopPropagation();
                  showAafterB(hiddenNote.id, note.id);
                }}
                offset={hiddenBackLinks.length - (i + 1)}
                className={`hidden-top-card-${hiddenBackLinks.length - i}`}
                id={hiddenNote.id}
              />
            ))}
          </HiddenWrapperTop>
          {/* <NonHoverableArea offset={hiddenBackLinks.length} isLeft /> */}
          <HiddenWrapperBottom numOfLinks={hiddenLinks.length}>
            {hiddenLinks.map((hiddenNote, i) => (
              <HiddenCard
                key={`hl-${hiddenNote.id}`}
                note={hiddenNote}
                onClick={(e) => {
                  e.stopPropagation();
                  showAbeforeB(hiddenNote.id, note.id);
                }}
                offset={hiddenLinks.length - (i + 1)}
                className={`hidden-bottom-card-${hiddenLinks.length - i}`}
                id={hiddenNote.id}
                isOnBottom
              />
            ))}
          </HiddenWrapperBottom>
          <LinkPort ref={linkPortRef} isShown={showLinkPort} onClick={toggleLinks} />
          <Card key={note.id} note={note} />
          <BacklinkPort ref={backlinkPortRef} isShown={showBacklinkPort} onClick={toggleBacklinks} />
        </CardWrapper>

        {
          // TODO - need the previous backlinkPortRef not the current one...
        }
        {/* This is the spacer and dropzone beneath a card */}
        <CardSpacer linkTo={parent || null} afterId={note.id} backlinkPortRef={parentPortRef} />
        <CardDropzone linkTo={parent || null} afterId={note.id} />
      </div>
      <CardList>
        <TreePadding>
          <CardDropzone linkTo={note} afterId={note.id} />
          {/* This is the Top spacer and dropzone for a new tree */}
          <CardSpacer linkTo={note} afterId={note.id} backlinkPortRef={backlinkPortRef} />
        </TreePadding>
        {shownBranches.map((branch) => (
          <Branch key={`tree-${branch.id}`}>
            <CardTree
              note={branch}
              shownTree={shownTree}
              setPortRefMap={setPortRefMap}
              parent={note}
              parentPortRef={backlinkPortRef}
            />
            <CardDropzone linkTo={note} afterId={branch.id} />
          </Branch>
        ))}
      </CardList>
    </CardTreeWrapper>
  );
};
