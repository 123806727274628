import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors, text } from 'core/styles';

type SurfaceType = 'Tree' | 'Graph' | 'Focus' | 'Flow D3 Force' | 'Flow Dagre';

// map the path to the type
const surfaceTypeMap: Record<SurfaceType, string> = {
  Tree: 'tree',
  Graph: 'graph',
  Focus: 'focus',
  'Flow D3 Force': 'flowd3force',
  'Flow Dagre': 'flowdagre',
};

const slug2SurfaceType = (slug: string): SurfaceType | undefined => {
  const entry = Object.entries(surfaceTypeMap).find(([_, value]) => value.toLowerCase() === slug.toLowerCase());
  return entry ? (entry[0] as SurfaceType) : undefined;
};

const StyledSelect = styled.select`
  font-size: ${text.size.primary};
  font-family: ${text.family.primary};
  padding: 1.375rem;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  background: ${colors.bg.primary};
  box-shadow: ${colors.shadow.surfacenav};
`;

const StyledOption = styled.option`
  padding: 8px;
`;

const SurfaceMenu: React.FC = () => {
  const [currentSurface, setCurrentSurface] = useState<SurfaceType>('Tree');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSurfaceChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newSurface = event.target.value as SurfaceType;
    setCurrentSurface(newSurface);
    const newPath = `/${surfaceTypeMap[newSurface]}${location.search}`;
    navigate(newPath);
  };

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    const slug = path.slice(1);
    const surfaceType = slug2SurfaceType(slug);
    if (!surfaceType) throw Error(`slug does not match any surface type: ${slug}`);
    setCurrentSurface(surfaceType);
  }, [location.pathname]);

  return (
    <StyledSelect value={currentSurface} onChange={handleSurfaceChange}>
      {Object.keys(surfaceTypeMap).map((surfaceType) => (
        <StyledOption key={surfaceType} value={surfaceType}>
          {surfaceType}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default SurfaceMenu;
