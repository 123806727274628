import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { Card } from 'src/components/Cards/Card';
import { Note } from 'core/types';
import HiddenCard, { HiddenWrapperTop, HiddenWrapperBottom } from 'src/components/Cards/HiddenCard';
import { ShownIdsContext } from 'src/context/ShownContext';
import { LinkHandle, BacklinkHandle } from './Handle';

const CardWrapper = styled.div`
  position: relative;
  height: fit-content;
  /* border: 1px solid green; */
  &:hover {
    z-index: 1001;
  }
`;

export const FlowCard: React.FC<{ data: Note }> = ({ data }) => {
  const { shownIds, surfaceNoteMap, showAafterB, showAbeforeB } = useContext(ShownIdsContext);

  const hiddenBacklinks = useMemo(() => {
    const hiddenIds = data.backlinks.filter(
      (id) => !shownIds.includes(id) && surfaceNoteMap[id] && surfaceNoteMap[id].type !== 'annote',
    );
    return hiddenIds.map((id) => surfaceNoteMap[id]);
  }, [shownIds, surfaceNoteMap, data]);

  const hiddenLinks = useMemo(() => {
    const hiddenIds = data.links.filter(
      (id) => !shownIds.includes(id) && surfaceNoteMap[id] && surfaceNoteMap[id].type !== 'annote',
    );
    return hiddenIds.map((id) => surfaceNoteMap[id]);
  }, [shownIds, surfaceNoteMap, data]);

  return (
    <CardWrapper>
      <HiddenWrapperTop numOfBacklinks={hiddenBacklinks.length}>
        {hiddenBacklinks.map((hiddenNote, i) => (
          <HiddenCard
            key={`hl-${hiddenNote.id}`}
            note={hiddenNote}
            onClick={(e) => {
              e.stopPropagation();
              showAafterB(hiddenNote.id, data.id);
            }}
            offset={hiddenBacklinks.length - (i + 1)}
            className={`hidden-top-card-${hiddenBacklinks.length - i}`}
            id={hiddenNote.id}
          />
        ))}
      </HiddenWrapperTop>
      <HiddenWrapperBottom numOfLinks={hiddenLinks.length}>
        {hiddenLinks.map((hiddenNote, i) => (
          <HiddenCard
            key={`hl-${hiddenNote.id}`}
            note={hiddenNote}
            onClick={(e) => {
              e.stopPropagation();
              showAbeforeB(hiddenNote.id, data.id);
            }}
            offset={hiddenLinks.length - (i + 1)}
            className={`hidden-bottom-card-${hiddenLinks.length - i}`}
            id={hiddenNote.id}
            isOnBottom
          />
        ))}
      </HiddenWrapperBottom>
      <BacklinkHandle id={data.id} />
      <Card note={data}></Card>
      <LinkHandle id={data.id} />
    </CardWrapper>
  );
};
