import React from 'react';
import { Handle, Position } from '@xyflow/react';
import styled, { css } from 'styled-components';
import { useDragging } from 'src/context/DraggingContext';

const HandleWrapper = styled.div<{ position: Position; isHidden: boolean }>`
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 2000;
  ${({ position }) =>
    position === Position.Left &&
    css`
      left: -20px;
      bottom: 18px;
    `}
  ${({ position }) =>
    position === Position.Right &&
    css`
      right: -20px;
      top: 18px;
    `}
`;
const RegularHandleSVG = (): React.ReactElement => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="15.5" height="15.5" rx="7.75" fill="#F5F5F5" />
    <rect x="1.25" y="1.25" width="15.5" height="15.5" rx="7.75" stroke="#878683" stroke-width="1.5" />
    <path
      d="M10.4302 4.99951L6.5 9.06933L10.5698 12.9995"
      stroke="#878683"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

const HoverHandleSVG = (): React.ReactElement => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="33.5" height="33.5" rx="16.75" fill="#5D6DD9" />
    <rect x="1.25" y="1.25" width="33.5" height="33.5" rx="16.75" stroke="#5D6DD9" stroke-width="1.5" />
    <path d="M18 12.666V23.3327" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.667 18H23.3337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const StyledHandle = styled(Handle)`
  width: 18px;
  height: 18px;
  border: none;
  background: transparent;
`;

const SVGContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }

  .regular-handle {
    display: block;
  }

  .hover-handle {
    display: none;
  }

  ${HandleWrapper}:hover & {
    .regular-handle {
      display: none;
    }
    .hover-handle {
      display: block;
    }
  }
`;

interface CustomHandleProps {
  type: 'source' | 'target';
  position: Position;
  id?: string;
}

const CustomHandle: React.FC<CustomHandleProps> = ({ type, position, id }) => {
  const { draggingConnectionId, draggingConnectionType } = useDragging();
  const isHidden = Boolean(
    draggingConnectionId !== null && draggingConnectionId !== id! && type === draggingConnectionType,
  );
  return (
    <HandleWrapper position={position} isHidden={isHidden}>
      <StyledHandle type={type} position={position} id={id} />
      <SVGContainer>
        <div className="regular-handle">
          <RegularHandleSVG />
        </div>
        <div className="hover-handle">
          <HoverHandleSVG />
        </div>
      </SVGContainer>
    </HandleWrapper>
  );
};

export const LinkHandle: React.FC<{ id?: string }> = ({ id }) => (
  <CustomHandle type="source" position={Position.Left} id={id} />
);

export const BacklinkHandle: React.FC<{ id?: string }> = ({ id }) => (
  <CustomHandle type="target" position={Position.Right} id={id} />
);
