import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ComingSoon from 'src/routes/ComingSoon';
import ThinkApp from './routes/ThinkApp';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TreeSurface from './routes/Surfaces/Tree/TreeSurface';
import Login from './routes/Login';
import Logout from './routes/Logout';
import RequireAuth from './components/RequireAuth';
import FocusSurface from './routes/Surfaces/Focus/FocusSurface';
import GraphSurface from './routes/Surfaces/GraphSurface';
import FlowD3ForceSurface from './routes/Surfaces/Flow/FlowD3ForceSurface';
import FlowDagreSurface from './routes/Surfaces/Flow/FlowDagreSurface';
// import CreateUserLogin from './routes/CreateUserLogin';

const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/soon" element={<ComingSoon />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          {/* <Route path="/login/annon" element={<CreateUserLogin />} /> */}

          {/* Authed Routes */}
          <Route
            path="/focus"
            element={
              <RequireAuth>
                <ThinkApp surface={<FocusSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/graph"
            element={
              <RequireAuth>
                <ThinkApp surface={<GraphSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/flowd3force"
            element={
              <RequireAuth>
                <ThinkApp surface={<FlowD3ForceSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/flowdagre"
            element={
              <RequireAuth>
                <ThinkApp surface={<FlowDagreSurface />} />
              </RequireAuth>
            }
          />
          <Route
            path="/tree"
            element={
              <RequireAuth>
                <ThinkApp surface={<TreeSurface />} />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Navigate to="/flowdagre" replace />} />
          <Route path="*" element={<div>Route not found</div>} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
