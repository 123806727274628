import React from 'react';
import { ControlButton } from '@xyflow/react';

interface AutoArrangeButtonProps {
  onArrange: () => void;
}

export const AutoArrangeButton: React.FC<AutoArrangeButtonProps> = ({ onArrange }) => {
  return (
    <ControlButton onClick={onArrange} title="Auto Arrange">
      A
    </ControlButton>
  );
};
